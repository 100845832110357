import { Data, Model, List, NeoModel, Validation } from '@singularsystems/neo-core';
import { AppService, Types } from '../../AwardsTypes';
import MyAwardLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';
import MyAwardLookupCriteria from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupCriteria';
import { MyAwardQuestionnaireComponentComponentVM } from './Components/MyAwardQuestionnaireComponent';
import { MyAwardDocumentComponentVM } from './Components/MyAwardDocumentComponent';
import { MyAwardAcceptSuccessComponentVM } from './Components/MyAwardAcceptSuccessComponent';
import MyAwardsVMBase from './MyAwardsVMBase';
import ParticipantOfferAwardDocumentLookup from '../../../Common/Models/AwardDocuments/Queries/ParticipantOfferAwardDocumentLookup';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';
import { MyAwardOptionSelectionComponentVM } from './Components/MyAwardOptionSelectionComponent';
import MyAwardLookupIncludeOptions from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupIncludeOptions';
import DeclineAwardsCommand from '../../Models/Approvals/Commands/DeclineAwardsCommand';
import AcceptAwardsCommand from '../../Models/ParticipantOffers/Commands/AcceptAwardsCommand';
import MyAwardDocumentLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardDocumentLookup';
import { AwardDocumentAcceptanceType } from '../../../Common/Models/AwardDocuments/AwardDocumentAcceptanceType.enum';
import AcceptAwardCommandDetail from '../../Models/ParticipantOffers/Commands/AcceptAwardCommandDetail';
import ParticipantEntityLookup from '../../Models/ParticipantOffers/Queries/ParticipantEntityLookup';

export enum AcceptAwardViewMode {
    Awards = "awards",
    Select = "select",
    Questionnaire = "questionnaire",
    Document = "document",
    Success = "success",
    AcceptedAwardSummary = "acceptedAwardSummary"
}

interface ILoadRequest { participantOfferId: number | null, mode: AcceptAwardViewMode, participantOfferAwardDocumentId?: number, incentiveSchemeDocumentId?: number }
interface IAcceptStep { award: MyAwardLookup | null, mode: AcceptAwardViewMode, participantOfferAwardDocumentId?: number, incentiveSchemeDocumentId?: number }

@NeoModel
export default class AcceptAwardsVM extends MyAwardsVMBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private commandClient = AppService.get(Types.Awards.ApiClients.ParticipantAcceptanceCommandApiClient),
        private participantsApiClient = AppService.get(Types.Participants.ApiClients.MainApiClient),
        private authService = AppService.get(Types.Shared.Services.STAuthenticationService)) {

        super(taskRunner);

        this.optionSelectionVM = new MyAwardOptionSelectionComponentVM(this.setupData.bind(this), this.taskRunner);
        this.optionSelectionVM.selectionChangedHandler = this.onSelectionChange.bind(this);

        this.questionnaireComponentVM = new MyAwardQuestionnaireComponentComponentVM(this.taskRunner);
        this.questionnaireComponentVM.onAnswersSaved = this.onQuestionnaireAnswersSaved.bind(this);

        this.documentComponentVM = new MyAwardDocumentComponentVM(this.taskRunner);
        
        this.documentComponentVM.documentCompletedHandler = this.onDocumentCompleted.bind(this);
        this.acceptSuccessComponentVM = new MyAwardAcceptSuccessComponentVM(this.setupData.bind(this), this.taskRunner);
    }

    public viewMode = AcceptAwardViewMode.Awards;
    public stepIndex = 0;
    public acceptSteps: IAcceptStep[] = [];
    private acceptingAwards: MyAwardLookup[] | null = null;
    public viewingAcceptedContent = false;

    public optionSelectionVM: MyAwardOptionSelectionComponentVM;
    public questionnaireComponentVM: MyAwardQuestionnaireComponentComponentVM;
    public documentComponentVM: MyAwardDocumentComponentVM;
    public acceptSuccessComponentVM: MyAwardAcceptSuccessComponentVM;

    private loadRequest: ILoadRequest | null = null;

    public selectedAward: MyAwardLookup | null = null;

    public participantEntities = new List(ParticipantEntityLookup);

    public showParticipantEntityModal: boolean = false;

    protected initialiseTask: Promise<void> | null = null;
    private acceptAwardsInitializing = true;
    private loadOfferInitializing = false;

    protected get pendingAwardsIncludes() {
        return { includeDocuments: true, includeQuestionnaires: true, includeAwardConfig: true } as MyAwardLookupIncludeOptions;
    }

    public async initialise() {
        // get the base to start loading
        const resultTask = this.taskRunner.waitFor(super.initialise());

        this.myAwardInfoTaskRunner.run(async () => {
            const result = await this.myAwardsApiClient.getPendingAwardInfo();
            this.myAwardsInfoLookup.set(result.data);
        });

        // then start loading the pages
        this.initialiseTask = Promise.all([resultTask, this.pendingAwardsPageManager.refreshData()]).then(
            async () => {
                this.acceptAwardsInitializing = false;
                await this.setupData(this.pendingAwardsPageManager.data);
            });

        await this.loadParticipantEntities();

        await this.initialiseTask;

        this.initialiseTask = null;
    }

    protected async setupData(data: MyAwardLookup[]) {
        if (!this.acceptAwardsInitializing) {
            await super.setupData(data);

            if (this.loadRequest) {
                this.loadAwardView(this.loadRequest);
            }

            this.noData = data.length === 0;
        }
    }

    public noData = false;

    public acceptTermsAndConditions = false;

    public selectedAwardsReadyForAcceptance() {
        return this.pageAwardSelector.selectedAwards.length > 0 && this.pageAwardSelector.selectedAwards.every(c => (c as MyAwardLookup).readyForAcceptance);
    }

    public get selectedHaveAdditionalAcceptSteps() {
        return this.pageAwardSelector.selectedAwards.length > 0 && this.pageAwardSelector.selectedAwards.some(c => (c as MyAwardLookup).hasAdditionalAcceptSteps);
    }

    public selectedAwardsReadyForDecline() {
        return this.pageAwardSelector.selectedAwards.length > 0 &&
            this.pageAwardSelector.selectedAwards.every(c => (c as MyAwardLookup).readyForDecline);
    }

    public startAccept() {
        this.setupAccept();
    }

    public async back() {
        if (this.viewingAcceptedContent) {
            this.viewMode = AcceptAwardViewMode.Success;
        } else {
            if (this.stepIndex === 0) {
                this.selectedAward = null;
                this.viewMode = AcceptAwardViewMode.Awards;
            } else {
                this.stepIndex--;
                await this.loadAcceptStep();
            }
        }
    }

    private async canLeaveStep() {
        // if questionnaire, save it
        if (this.viewMode === AcceptAwardViewMode.Questionnaire) {
            if (this.questionnaireComponentVM.updateQuestionnaireAnswerCommand.isValid) {
                this.questionnaireComponentVM.validationDisplayMode = undefined;
                await this.saveQuestionnaireAnswers();
                return true;
            } else {
                this.questionnaireComponentVM.validationDisplayMode = Validation.DisplayMode.Always;
                return false;
            }
        }
        else if (this.viewMode === AcceptAwardViewMode.Document) {
            // make sure document is completed
            if (!this.documentComponentVM.awardDocument.isCompleted) {
                if (this.documentComponentVM.awardDocument.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions) {
                    this.notifications.addWarning("Please accept the terms and conditions", null, NotificationDuration.Standard);
                } else {
                    this.notifications.addWarning("Please upload required document", null, NotificationDuration.Standard);
                }
                return false;
            }
        }

        return true;
    }

    public async next() {
        if (await this.canLeaveStep()) {
            this.stepIndex++;
            await this.loadAcceptStep();
        }
    }

    private async setupAccept() {
        this.setupAcceptSteps();
        this.stepIndex = 0;
        await this.loadAcceptStep();
    }

    private async loadAcceptStep() {
        const step = this.acceptSteps[this.stepIndex];
        if (step.award && step.award !== this.selectedAward) {
            this.selectedAward = step.award;
        }
        await this.loadAwardViewMode(step.award,
            { participantOfferId: step.award?.participantOfferId ?? null, mode: step.mode, participantOfferAwardDocumentId: step.participantOfferAwardDocumentId, incentiveSchemeDocumentId: step.incentiveSchemeDocumentId });
    }

    private setupAcceptSteps() {
        const acceptSteps: IAcceptStep[] = [];
        let myAward: MyAwardLookup | null = null;
        this.acceptingAwards = this.pageAwardSelector.selectedAwards.map(a => a as MyAwardLookup);
        for (const award of this.pageAwardSelector.selectedAwards) {
            if (myAward === null || myAward.participantOfferId !== award.participantOfferId) {
                myAward = award as MyAwardLookup;
                if (myAward.canMakeSelection) {
                    acceptSteps.push({ award: myAward, mode: AcceptAwardViewMode.Select });
                }
                if (myAward.hasQuestionnaire) {
                    acceptSteps.push({ award: myAward, mode: AcceptAwardViewMode.Questionnaire });
                }
                if (myAward.hasDocumentsThatRequireCompletion) {
                    for (const doc of myAward.documents.filter(d => d.requiresCompletion)) {
                        acceptSteps.push({ award: myAward, mode: AcceptAwardViewMode.Document, participantOfferAwardDocumentId: doc.participantOfferAwardDocumentId, incentiveSchemeDocumentId: doc.incentiveSchemeDocumentId ?? undefined });
                    }
                }
                if (myAward.hasAcceptedAwardSummary){
                     acceptSteps.push({ award: myAward, mode: AcceptAwardViewMode.AcceptedAwardSummary });
                }
            }
        }

        acceptSteps.push({ award: null, mode: AcceptAwardViewMode.Success });
        this.acceptSteps = acceptSteps;
    }

    private async acceptAwards(awards: MyAwardLookup[]) {
        let participantOfferIds = awards.map(a => a.participantOfferId);
        participantOfferIds = participantOfferIds.filter((x, y) => participantOfferIds.indexOf(x) === y);

        const command = new AcceptAwardsCommand();
        for (const award of awards) {
            const commandDetail = new AcceptAwardCommandDetail();
            commandDetail.participantOfferId = award.participantOfferId;
            commandDetail.documentGenerationDate = award.awardDocumentSetLastGeneratedOn;
            commandDetail.participantEntityId = award.participantEntityId;
            command.awards.push(commandDetail);
        }

        try {
            await this.taskRunner.run(async options => {
                await this.commandClient.acceptAwards(command.toJSObject());
                this.notifications.addSuccess("Awards accepted", null, NotificationDuration.Standard);

                this.removeSelectedAwards();

                this.acceptTermsAndConditions = false;
                (this.meta as unknown as Model.TransformMetaType<AcceptAwardsVM>).acceptTermsAndConditions.validator.hasBlurred = false;
            })
        }
        catch (error) {
            this.acceptSteps = [];
            this.acceptingAwards = null;
            this.stepIndex = 0;
            this.viewingAcceptedContent = false;
            this.viewMode = AcceptAwardViewMode.Awards;
            this.pageAwardSelector.reset();
            this.pendingAwardsPageManager.refreshPage();

            throw error;
        }
    }

    public startDecline() {
        this.declineSelectedAwards();
    }

    private async declineSelectedAwards() {
        const command = new DeclineAwardsCommand();
        command.participantOfferIds = this.pageAwardSelector.selectedAwards.filter(c => (c as MyAwardLookup).readyForDecline).map(c => c.participantOfferId);

        await this.taskRunner.run(async () => await this.commandClient.declineAwards(command.toJSObject()));
        this.notifications.addSuccess("Awards declined", null, NotificationDuration.Standard);

        this.removeSelectedAwards();

        this.acceptTermsAndConditions = false;
        (this.meta as unknown as Model.TransformMetaType<AcceptAwardsVM>).acceptTermsAndConditions.validator.hasBlurred = false;
    }

    private removeSelectedAwards() {
        for (const award of this.pageAwardSelector.selectedAwards) {
            const lookup = award as MyAwardLookup;
            if (this.pendingAwardsPageManager.data.indexOf(lookup) !== -1) {
                this.pendingAwardsPageManager.data.remove(lookup);
            }
        }
        this.pageAwardSelector.reset();

        if (this.pendingAwardsPageManager.data.length === 0) {
            if (this.pendingAwardsPageManager.totalPages > 1) {
                this.pendingAwardsPageManager.refreshData();
            } else {
                this.noData = true;
            }
        }
    }

    public get requiresQuestionnaireColumn() {
        return this.pendingAwardsPageManager.data.some(c => c.hasQuestionnaire);
    }

    public async saveQuestionnaireAnswers() {
        if (this.questionnaireComponentVM.updateQuestionnaireAnswerCommand.isValid) {
            await this.questionnaireComponentVM.saveQuestionnaireAnswers()
            this.notifications.addSuccess("Answers saved", null, NotificationDuration.Standard);
            return true;
        } else {
            this.questionnaireComponentVM.updateQuestionnaireAnswerCommand.validator.forceShowErrors = true;
            return false;
        }
    }

    public async loadAwardOptionSelectView(participantOfferId: number) {
        if (this.initialiseTask) {
            await this.initialiseTask;
        }

        const loaded = await this.loadAwardView({ participantOfferId: participantOfferId, mode: AcceptAwardViewMode.Select });
        return loaded;
    }

    public async loadAwardQuestionnaireView(participantOfferId: number) {
        if (this.initialiseTask) {
            await this.initialiseTask;
        }

        const loaded = await this.loadAwardView({ participantOfferId: participantOfferId, mode: AcceptAwardViewMode.Questionnaire });
        return loaded;
    }

    public async loadAcceptedAwardQuestionnaireView(award: MyAwardLookup) {
        const loaded = await this.loadAwardViewMode(award,
            { participantOfferId: award.participantOfferId, mode: AcceptAwardViewMode.Questionnaire });
        this.viewingAcceptedContent = true;
        return loaded;
    }

    public async loadAwardDocumentView(participantOfferId: number, participantOfferAwardDocumentId: number | null, incentiveSchemeDocumentId: number | null) {
        if (this.initialiseTask) {
            await this.initialiseTask;
        }

        const loaded = await this.loadAwardView({
            participantOfferId: participantOfferId,
            mode: AcceptAwardViewMode.Document,
            participantOfferAwardDocumentId: participantOfferAwardDocumentId ?? undefined,
            incentiveSchemeDocumentId: incentiveSchemeDocumentId ?? undefined,
        });
        this.viewingAcceptedContent = false;
        return loaded;
    }

    public async loadAcceptedAwardDocumentView(award: MyAwardLookup, doc: MyAwardDocumentLookup) {
        const loaded = await this.loadAwardViewMode(
            award,
            { participantOfferId: award.participantOfferId, mode: AcceptAwardViewMode.Document, participantOfferAwardDocumentId: doc.participantOfferAwardDocumentId, incentiveSchemeDocumentId: doc.incentiveSchemeDocumentId ?? undefined });
        this.viewingAcceptedContent = true;
        return loaded;
    }

    private async loadAwardView(request: ILoadRequest) {
        if (this.pageLoaded && this.pendingAwardsPageManager.data.length > 0) {
            let award = this.pendingAwardsPageManager.data.find(c => c.participantOfferId === request.participantOfferId);
            if (award) {
                return await this.loadAwardViewMode(award, request);
            }
            else {
                // the award may not be in this page, so try load it on its own
                const criteria = new MyAwardLookupCriteria();
                criteria.participantOfferId = request.participantOfferId;
                const pageRequest = new Data.PageRequest(criteria);
                const result = await this.taskRunner.waitFor(this.myAwardsApiClient.getPendingAwards(pageRequest.toQueryObject(), this.pendingAwardsIncludes));
                if (result.data.entityList.length > 0) {
                    const awardData = result.data.entityList.filter(c => c.participantOfferId === request.participantOfferId);
                    if (awardData.length > 0) {
                        // award loaded
                        const awards = new List(MyAwardLookup);
                        awards.set(awardData);
                        let prevAward: MyAwardLookup | null = null;
                        for (award of awards) {
                            this.pendingAwardsPageManager.data.push(award);
                            this.setupLookup(award, prevAward, this.pendingAwardsPageManager.data);
                        }
                        return await this.loadAwardViewMode(awards[0], request);
                    }
                }
            }
        } else {
            this.loadRequest = request;
            return true;
        }
        return false;
    }

    private async loadAwardViewMode(award: MyAwardLookup | null, request: ILoadRequest) {
        this.selectedAward = award;
        this.loadRequest = null;
        if (award && request.mode === AcceptAwardViewMode.Select) {
            this.viewMode = request.mode;
            await this.loadOfferSelection(award);
            return true;
        }
        else if (award && request.mode === AcceptAwardViewMode.Questionnaire) {
            this.viewMode = request.mode;
            await this.loadQuestionnaire(award);
            return true;
        }
        else if (award && request.mode === AcceptAwardViewMode.Document) {
            const document = await this.loadAwardDocument(award, request);
            if (!document?.isDownloadOnly) {
                this.viewMode = request.mode;
                return true;
            }
            return false;
        }
        else if (request.mode === AcceptAwardViewMode.Success) {
            // accept the awards
            await this.acceptAwards(this.acceptingAwards!);

            this.acceptSuccessComponentVM.setupAwards(this.acceptingAwards!.map(a => a.participantOfferId));
            this.viewMode = request.mode;
            // clear the steps
            this.acceptSteps = [];
            this.stepIndex = 0;
            return true;
        }
        else if (award && request.mode === AcceptAwardViewMode.AcceptedAwardSummary) {
            if (award.hasAcceptedAwardSummary && request.participantOfferId) {
                const document = await this.documentComponentVM.generateAcceptedAwardsSummary(request.participantOfferId, award.awardPrepId);
                this.documentComponentVM.awardDocumentComponentVM.document = document
                this.viewMode = request.mode;
                return true;
            }
            return false;
        }
        else {
            this.selectedAward = null;
            this.viewingAcceptedContent = false;
        }
        return false;
    }

    public async loadParticipantEntities() {
        const result = await this.participantsApiClient.getParticipantEntities(this.authService.user!.participantId);
        this.participantEntities.set(result.data);
    }

    private async loadOfferSelection(item: MyAwardLookup) {
        await this.optionSelectionVM.setupAwards(item.participantOfferId);
        if (!this.loadOfferInitializing)
        {
            var incentiveSchemesResult = await this.cache.incentiveSchemes.getDataAsync();
            this.optionSelectionVM.incentiveSchemes.set(incentiveSchemesResult);
            this.documentComponentVM.awardDocumentComponentVM.isAcceptedAwardSummary = false;
            this.loadOfferInitializing = true
        }
    }

    private onSelectionChange() {
        if (this.selectedAward) {
            const sameLoadedAwards = this.pendingAwardsPageManager.data.filter(c => c.participantOfferId === this.selectedAward!.participantOfferId);
            const selectedTotal = this.optionSelectionVM.totalSelectionPercent();
            for (const award of sameLoadedAwards) {
                award.totalSelectedPercent = selectedTotal;
                award.selectedOn = new Date();
            }
        }
    };

    private async loadQuestionnaire(item: MyAwardLookup) {
        if (item.questionnaire) {
            var questionnaireAnsweredId = await this.taskRunner.waitFor(
                this.questionnaireComponentVM.loadQuestionnaireAnswers(item.participantOfferId, item.questionnaire.questionnaireId, item.questionnaire.questionnaireAnsweredId));

            item.questionnaire.questionnaireAnsweredId = questionnaireAnsweredId;
        }
    }

    protected onQuestionnaireAnswersSaved() {
        if (this.selectedAward) {
            const awards = this.pendingAwardsPageManager.data.filter(a => a.participantOfferId === this.selectedAward?.participantOfferId);

            for (const award of awards) {
                if (award.questionnaire) {
                    award.questionnaire.answersCount = this.questionnaireComponentVM.updateQuestionnaireAnswerCommand.questionnaireAnswered.answeredQuestions;
                    award.questionnaire.completedOn = this.questionnaireComponentVM.updateQuestionnaireAnswerCommand.questionnaireAnswered.completedOn;
                }
            }
        }
    }

    public async loadAwardDocument(award: MyAwardLookup, request: ILoadRequest) {

        if (award.documents && award.documents.length > 0) {
            // find the document on the award
            let awardDocument: MyAwardDocumentLookup | null = null;
            let selectedDocument = award.documents.find(c => c.participantOfferAwardDocumentId === 0);

            if (request.incentiveSchemeDocumentId) {
                awardDocument = award.documents.find(d => d.incentiveSchemeDocumentId === request.incentiveSchemeDocumentId) ?? null;
                if (awardDocument) {
                    selectedDocument = awardDocument;
                }
            }
            else if (request.participantOfferAwardDocumentId) {
                awardDocument = award.documents.find(d => d.participantOfferAwardDocumentId === request.participantOfferAwardDocumentId) ?? null;
                if (awardDocument) {
                    selectedDocument = awardDocument;
                }
            }

            if (selectedDocument && !selectedDocument.document && request.participantOfferId) {
                selectedDocument.document = await this.documentComponentVM.loadParticipantAwardDocument(request.participantOfferId, request.participantOfferAwardDocumentId ?? 0, request.incentiveSchemeDocumentId ?? null);
                if (awardDocument) {
                    this.documentComponentVM.awardDocument = awardDocument;
                }
            }

            if (selectedDocument && selectedDocument.document) {
                this.documentComponentVM.awardDocumentComponentVM.document = selectedDocument.document;
                this.loadParticipantAwardDocument(selectedDocument.document);
            }

            return selectedDocument?.document;
        }
        return null;
    }

    private loadParticipantAwardDocument(document: ParticipantOfferAwardDocumentLookup) {
        if (document.isDownloadOnly) {
            this.documentComponentVM.awardDocumentComponentVM.downloadDocument(document);

            if (this.viewingAcceptedContent) {
                this.viewMode = AcceptAwardViewMode.Success;
            } else {
                this.viewMode = AcceptAwardViewMode.Awards;
                this.selectedAward = null;
            }

        } else {
            this.viewMode = AcceptAwardViewMode.Document;
        }
    }

    private onDocumentCompleted(document: ParticipantOfferAwardDocumentLookup) {
        const award = this.pendingAwardsPageManager.data.find(c => c.participantOfferId === document.participantOfferId);
        if (award) {
            const awardDoc = award.documents.find(c => c.participantOfferAwardDocumentId === document.participantOfferAwardDocumentId);
            if (awardDoc) {
                awardDoc.respondedOn = document.respondedOn;
                awardDoc.uploadedFileName = document.uploadedFileName;
            }
        }
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        rules.failWhen(c => !c.acceptTermsAndConditions, "You must accept the terms and conditions");
    }
}